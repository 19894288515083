export const exampleQuestions = [
  {
    id: 1,
    question: '항암치료 시 잡곡밥 먹으면 안 되나요?',
    url: 'https://www.lunit.care/boards/107/',
    boardId: 107,
  },
  {
    id: 2,
    question: '어머니께서 의심단계인데 심각한 건가요?',
    url: 'https://www.lunit.care/boards/106/',
    boardId: 106,
  },
  {
    id: 3,
    question: '허셉틴 치료는 몇 번이나 하나요?',
    url: 'https://www.lunit.care/boards/98/',
    boardId: 98,
  },
]
