export const PROVIDER_SEPARATOR = '::'

// 인증 수단
export const AUTH_PROVIDER = {
  KAKAO: 'KAKAO',
  NAVER: 'NAVER',
  APPLE: 'APPLE',
} as const
export type AuthProvider = keyof typeof AUTH_PROVIDER

export const ACTION_TYPES = {
  // 질문하기 버튼 클릭하여 로그인 후, 질문하기 모달을 열고자 할 때 사용하는 쿼리 파라미터
  CREATE_BOARD: 'actionType=board',
  // 웹에서 회원탈퇴 후, 회원 탈퇴 alert을 보여주기 위한 쿼리 파라미터
  UNREGISTER_ALERT: 'actionType=unregister',
}
