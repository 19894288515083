import { TooltipInfo } from '@/types'

export const HOSPITALS_INTRO_TOOLTIP: TooltipInfo = {
  text: '암 진료 역량이 우수한 병원을 보여드립니다.',
  content: (
    <div>
      <p className="font-bold">암 적정성 평가 1등급 병원만 보여드립니다.</p>
      건강보험심사평가원에서는 병원을 대상으로 주요 암 진료과정을 평가합니다.
      우수한 평가를 받은 병원은 1등급을 부여합니다.
    </div>
  ),
  placement: 'bottom',
}

export const HOSPITAL_CLASS_TOOLTIP: TooltipInfo = {
  text: '상급종합',
  content: (
    <div>
      <p className="font-bold">
        본 병원은 보건복지부에서 지정한 상급종합병원입니다.
      </p>
      상급종합병원 진료 시 건강보험을 적용받으려면, 의원이나 병원에서 발급받은
      진료의뢰서나 이상 소견이 기재된 건강진단서를 지참하여야 합니다.
    </div>
  ),
  placement: 'bottom',
}

export const CANCER_ASSESSMENT_TOOLTIP: TooltipInfo = {
  text: '암진료 평가',
  content: (
    <p>
      건강보험심사평가원이 수행한 주요 암 진료 역량 평가에서 해당 병원이 1등급
      받은 이력을 보여드립니다.
    </p>
  ),
  placement: 'top',
}

export const FIELD_ASSESSMENT_TOOLTIP: TooltipInfo = {
  text: '평가 우수 진료분야',
  content: (
    <p>
      해당 병원이 서비스하는 진료 분야 중 진료 역량이 우수하다고 평가된 항목을
      보여드립니다. 치료, 약제 사용, 관리 등 전반적인 진료 항목에서
      평가받았습니다.
    </p>
  ),
  placement: 'top',
}

export const HOSPITAL_CLASS_CODE_FOR_TOOLTIP = '상급종합'

export const HOSPITAL_CLASSES = [
  'TERTIARY_GENERAL',
  'UNIVERSITY',
  'GENERAL',
  'HOSPITAL',
]

export const KOR_HOSPITAL_CLASSES = [
  '상급종합',
  '대학병원',
  '종합병원',
  '병원',
] as const

export const BUSINESS_DAYS = [
  'MONDAY',
  'TUESDAY',
  'WEDNESDAY',
  'THURSDAY',
  'FRIDAY',
  'SATURDAY',
  'SUNDAY_OR_HOLIDAY',
] as const

export const KOR_BUSINESS_DAYS = [
  '월',
  '화',
  '수',
  '목',
  '금',
  '토',
  '일/공휴일',
]
