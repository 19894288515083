import { URL_PATH } from '@/consts/urls'

export interface GuideBanner {
  index: number
  gaSelector2: string
  gaSelector3: string
}

export interface GuideButton {
  gaSelector: string
}

export const MID_GUIDE_BANNER_GA_SELECTOR = 'guide_main_con_2depth_image'
export const MID_GUIDE_TEXT_BUTTON_GA_SELECTOR = 'guide_main_con_2depth_text'
export const SUB_GUIDE_BUTTON_GA_SELECTOR = 'guide_main_con_3depth'

export const SESSION_GUIDE_ID = 'SESSION_GUIDE_ID'

export const PATH_TO_CANCER_TYPE = {
  [URL_PATH.GuidesBreast]: 'breast',
  [URL_PATH.GuidesMainBreast]: 'breast',
  [URL_PATH.GuidesStomach]: 'stomach',
  [URL_PATH.GuidesMainStomach]: 'stomach',
  [URL_PATH.GuidesThyroid]: 'thyroid',
  [URL_PATH.GuidesMainThyroid]: 'thyroid',
  [URL_PATH.GuidesLung]: 'lung',
  [URL_PATH.GuidesMainLung]: 'lung',
  [URL_PATH.GuidesColon]: 'colon',
  [URL_PATH.GuidesMainColon]: 'colon',
}
