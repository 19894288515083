export const MaxWidthSize = {
  sm: 'max-w-[480px]',
  md: 'max-w-[768px]',
  lg: 'w-full',
}

export const MarginX = {
  none: 'ml-0',
  center: 'mx-auto',
}

export const NestedMarginX = {
  none: 'mx-0',
  sm: 'mx-6',
}

// 하단 탭바의 높이
export const TAB_BAR_HEIGHT = 52

export const FAB_BOTTOM_POSITION = {
  web: 'bottom-[calc(24px_+_env(safe-area-inset-bottom))]',
  // 모바일 앱에서는 하단 탭바가 있어서 52px만큼 위로 올려야 한다.
  app: 'bottom-[calc(76px_+_env(safe-area-inset-bottom))]',
}

// 웹뷰에서 탭바가 있는 화면의 경우 하단 패딩을 준다.
export const BOTTOM_PADDING_STYLE = 'pb-[120px]'
