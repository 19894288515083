import { CANCER_TYPES } from './cancer'

export const PRODUCTION_URL = 'https://www.lunit.care'
export const DELETE_ACCOUNT_PATH = '/users/me/delete-account'

export const URL_PATH: Record<string, string> = {
  Main: '/',
  Boards: '/boards/',
  BoardCreate: '/boards/create/',
  BoardUpdate: '/boards/update/',
  GuidesMainBreast: '/breast/guides/main/',
  GuidesMainStomach: '/stomach/guides/main/',
  GuidesMainThyroid: '/thyroid/guides/main/',
  GuidesMainLung: '/lung/guides/main/',
  GuidesMainColon: '/colon/guides/main/',
  GuidesBreast: '/breast/guides/',
  GuidesStomach: '/stomach/guides/',
  GuidesThyroid: '/thyroid/guides/',
  GuidesLung: '/lung/guides/',
  GuidesLife: '/life/guides/',
  GuidesColon: '/colon/guides/',
  GetStarted: '/get-started/',
  MyCare: '/users/me/',
  MyCareBoard: '/users/me/board/',
  MyCareBoards: '/users/me/boards/',
  MyCareHealthInfo: '/users/me/health-information/',
  MyCareDeleteAccount: '/users/me/delete-account/',
  MyCounsels: '/users/me/counsels/',
  MyCounselsMeet: '/users/me/counsels/meet/',
  MyCounselsEtc: '/users/me/counsels/etc/',
  MyCounselsEdit: '/users/me/counsels/meet/edit/',
  MyProfile: '/users/me/profile/',
  MyBookmarks: '/users/me/bookmarks/',
  MyNotifications: '/users/me/notifications/',
  // AI 진료노트 소개 페이지
  AINotes: '/appointment-notes/',
  // 내 진료노트목록
  MyNotes: '/users/me/appointment-notes/',
  MyPlusReport: '/users/me/reports/',
  MyRegimens: '/users/me/regimens/',
  MyHospitalsAppointments: '/users/me/appointments/',
  MyHospitalsAppointmentForm: '/users/me/hospitals/appointments/form/',
  MyHospitalsAppointmentUpdate: '/users/me/hospitals/appointments/update/',
  MyAssistant: '/users/me/assistant/',
  HospitalsMain: '/hospitals/',
  HospitalsSearch: '/hospitals/search/',
  Notices: '/notices/',
  Service: '/service/',
  EventsMarch: '/events/202303_cancerpreventionday/',
  TermsService: '/terms/service/',
  TermsPrivacy: '/terms/privacy/',
  TermsMarketing: '/terms/marketing/',
  TermsImprovement: '/terms/improvement/',
  BoardAnswerProcess: '/board/answer-process/',
  ContentsList: '/contents/',
  Search: '/search/',
  Calculator: '/calculator/',
  Help: '/help/',
  Plus: '/plus/',
  PlusReport: '/plus/reports/',
  PlusReportStart: '/plus/reports/start/',
  PlusSampleReport: '/plus/reports/intro/',
  AppGnb: '/gnb/',
  ChannelTalkCS: '/channeltalk-cs/',
  Glossaries: '/glossaries/',
  Regimens: '/regimens/',
  HospitalsAppointment: '/hospitals/appointments/',
  Assistant: '/assistant/',
  PlusCounselsStart: '/plus/counsels/start/',
  ReferralEvent: '/events/202409_referral/',
}

export const GUIDES_MAIN_REDIRECT_PATH: Record<string, string> = {
  [CANCER_TYPES.breast]: URL_PATH.GuidesMainBreast,
  [CANCER_TYPES.stomach]: URL_PATH.GuidesMainStomach,
  [CANCER_TYPES.thyroid]: URL_PATH.GuidesMainThyroid,
  [CANCER_TYPES.colon]: URL_PATH.GuidesMainColon,
  // TODO: 대장암 용어 통일 필요 (백엔드 문의).
  [CANCER_TYPES.colorectal]: URL_PATH.GuidesMainColon,
  [CANCER_TYPES.lung]: URL_PATH.GuidesMainLung,
}

export const GUIDES_LIST_REDIRECT_PATH: Record<string, string> = {
  [CANCER_TYPES.breast]: URL_PATH.GuidesBreast,
  [CANCER_TYPES.stomach]: URL_PATH.GuidesStomach,
  [CANCER_TYPES.thyroid]: URL_PATH.GuidesThyroid,
  [CANCER_TYPES.colon]: URL_PATH.GuidesColon,
  // TODO: 대장암 용어 통일 필요 (백엔드 문의)
  [CANCER_TYPES.colorectal]: URL_PATH.GuidesColon,
  [CANCER_TYPES.lung]: URL_PATH.GuidesLung,
}

export const GUIDES_LIST_CANONICAL_PATH: Record<string, string> = {
  ...GUIDES_LIST_REDIRECT_PATH,
  life_style: URL_PATH.GuidesLife,
}

export const API_PATH = {
  MyInfo: 'me/',
  MyInterest: 'me/interest/',
  MyHealthInformation: 'me/health-information/',
  // 공지사항
  Notices: 'notice/',
  // 가이드 컨텐츠
  Guide: 'cancer_content/',
  // 병원 정보 API
  HospitalList: 'hospital/',
  HospitalFilter: 'hospital/summary/',
  // 내 계정 탈퇴
  Unregister: 'me/unregister/',
  // 질문과 답변
  Qna: 'qna/',
  // 질문과 답변 AI 추천 질문생성
  QnaByAI: 'qna/gen/gen_question/',
  // 추천 암 컨텐츠
  CONTENTS: 'home/cancer-contents/',
  // 검색 결과
  SEARCH: 'search/query/',
  // 검색 추천어
  SearchKeywords: 'search/suggest/',
  // 고객센터 (자주 묻는 질문)
  Faq: 'faq/',
  FaqCategory: 'faq/category/',
  // 유저가 동의한 서비스 약관
  MyServiceTerms: 'me/service-terms/',
  // 북마크
  Bookmarks: 'me/bookmarks/',
  // 내 질문 목록
  MyQuestions: 'me/questions/',
  FeatureFlag: 'feature-flag/',
  // 푸시 알림 리스트
  MyNotices: 'me/notice/',
  MyNoticesRead: 'me/notice/read/',
  // 샢플 플러스 리포트
  SampleReportMain: 'report/main/',
  SampleReportContents: 'report/contents/',
  // 내 플러스 리포트
  ReportOnboarding: 'onboarding/entries/',
  MyReportOnboarding: 'me/onboarding/entries/',
  MyReportContents: 'me/report/contents/',
  MyReportConditions: 'me/report/conditions/',
  MyReportMain: 'me/report/main/',
  // 암 용어 사전
  Dictionary: 'cancer_dictionary/',
  // 항암 정보
  Regimens: 'cancer-treatment/regimen/',
  // 항암 정보 필터
  RegimenFilter: 'cancer-treatment/filter/',
  // 항암 치료 정보 검색
  RegimenSearch: 'cancer-treatment/regimen/search/',
  // 내 병원 예약 form 기본 데이터
  MyHospitalInitialFormData: 'me/reserve/initial-formdata/',
  // 병원 예약 여부 확인
  MyHospitalAppointmentCheck: 'me/reserve/check/',
  // 병원 예약
  MyHospitalAppointment: 'me/reserve/reservation/',
  MyHospitalAppointments: 'me/reserve/reservations/',
  // 병원 예약 수정 form 데이터
  MyHospitalAppointmentFormData: 'me/reserve/change-formdata/',
  // 진료 노트
  ClinicalNotes: 'clinical_notes/',
  // AI 영양사 채팅
  MealPlanningChat: 'meal-planning/chat/',
  // 플러스 상담 예약
  PlusCounsel: 'counsel/',
  // 플러스 상담 신청
  MyPlusCounsel: 'me/counsel/',
  // 최신 상담 조회
  MyPlusCounselLatest: 'counsel/me/latest/',
  // 팬캔서 추천 목록
  CounselRelatedContents: 'counsel/related_content/',
  // 이벤트 관련 API 엔드포인트 (예: 이벤트 목록 조회, 이벤트 상세 정보 등)
  Event: 'event/',
  // 유저 피드백
  Feedback: 'feedback/',
}

// 모바일 앱의 메인 라우트. 안드로이드 앱에서 메인 라우트의 경우 뒤로가기 버튼을 보여주지 않는다.
export const APP_MAIN_PATHS = [
  URL_PATH.MyCare,
  URL_PATH.GetStarted,
  URL_PATH.GuidesMainBreast,
  URL_PATH.GuidesMainStomach,
  URL_PATH.GuidesMainThyroid,
  URL_PATH.GuidesMainLung,
  URL_PATH.GuidesMainColon,
  URL_PATH.GuidesLife,
  URL_PATH.HospitalsMain,
  URL_PATH.Boards,
  URL_PATH.Service,
  URL_PATH.Notices,
] as const

// iOS 앱의 메인 라우트. iOS 앱에서 메인 라우트의 경우 뒤로가기 버튼을 보여주지 않는다.
export const IOS_APP_MAIN_PATHS = [
  URL_PATH.MyCare,
  URL_PATH.GetStarted,
] as const
