export const CANCER_TYPES = {
  breast: 'breast',
  thyroid: 'thyroid',
  lung: 'lung',
  stomach: 'stomach',
  colon: 'colon',
  colorectal: 'colorectal',
} as const

export type CancerType = keyof typeof CANCER_TYPES

export const CANCERS = {
  BREAST: 'BREAST',
  COLON: 'COLON',
  LUNG: 'LUNG',
  STOMACH: 'STOMACH',
  THYROID: 'THYROID',
} as const

export type Cancers = keyof typeof CANCERS

export const KOR_CANCERS = [
  '유방암',
  '갑상선암',
  '폐암',
  '위암',
  '대장암',
] as const

// 한글 암종을 대문자 영어 암종으로 맵핑
export const KOR_TO_ENG_CANCER_MAP = {
  [KOR_CANCERS[0]]: CANCERS.BREAST,
  [KOR_CANCERS[1]]: CANCERS.THYROID,
  [KOR_CANCERS[2]]: CANCERS.LUNG,
  [KOR_CANCERS[3]]: CANCERS.STOMACH,
  [KOR_CANCERS[4]]: CANCERS.COLON,
} as const

export const KOR_CANCER_TYPES = {
  breast: KOR_CANCERS[0],
  thyroid: KOR_CANCERS[1],
  lung: KOR_CANCERS[2],
  stomach: KOR_CANCERS[3],
  colon: KOR_CANCERS[4],
  colorectal: KOR_CANCERS[4],
} as const

export const ORDERED_KOR_CANCER_TYPES = [
  KOR_CANCER_TYPES.breast,
  KOR_CANCER_TYPES.thyroid,
  KOR_CANCER_TYPES.lung,
  KOR_CANCER_TYPES.stomach,
  KOR_CANCER_TYPES.colorectal,
] // 암종 정렬 기준

export const CANCER_FILTERS = [
  'ALL',
  CANCERS.BREAST,
  CANCERS.STOMACH,
  CANCERS.THYROID,
  CANCERS.LUNG,
  CANCERS.COLON,
] as const

export type KorCancerType =
  typeof KOR_CANCER_TYPES[keyof typeof KOR_CANCER_TYPES]

export const LIFE_STYLE_TYPE = 'LIFE_STYLE'
export const KOR_LIFE_STYLE_TYPE = '생활백서'

export type CategoryType = Cancers | typeof LIFE_STYLE_TYPE
