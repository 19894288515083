/** 메인 페이지 상단과 Gnb 이벤트 배너의 swiper indicator 스타일 */
export const EVENT_SWIPER_INDICATOR_STYLE = [
  'absolute',
  '!bottom-2',
  '!left-[initial]',
  'z-10',
  'max-w-[40px]',
  'text-xs',
  'leading-[1.5]',
  'text-center',
  'rounded-[20px]',
  'bg-[rgba(0,0,0,0.8)]',
  'text-white',
  'px-2',
  'py-0.5',
].join(' ')
