import { URL_PATH } from '@/consts/urls'

export const SERVICE_MENUS = [
  {
    id: 1,
    text: '고객센터',
    url: URL_PATH.Help,
    gaSelector: 'mycare_qna',
  },
  {
    id: 2,
    text: '서비스 이용 약관',
    url: URL_PATH.TermsService,
    gaSelector: 'mycare_service',
  },
  {
    id: 3,
    text: '개인정보 처리 방침',
    url: URL_PATH.TermsPrivacy,
    gaSelector: 'mycare_privacy',
  },
] as const
