export const MOBILE_EVENTS = {
  KAKAO_LOGIN: 'KAKAO_LOGIN',
  NAVER_LOGIN: 'NAVER_LOGIN',
  LOGOUT: 'LOGOUT',
  GO_BACK: 'GO_BACK',
  ROUTE_CHANGE: 'ROUTE_CHANGE',
  WEB_LOGIN: 'WEB_LOGIN',
  WEB_LOGOUT: 'WEB_LOGOUT',
  WEB_UNREGISTER: 'WEB_UNREGISTER',
  SHOW_CLOSE_TOAST: 'SHOW_CLOSE_TOAST',
  HIDE_CLOSE_TOAST: 'HIDE_CLOSE_TOAST',
  SHOW_PLUS_MODAL: 'SHOW_PLUS_MODAL',
  APP_CANCEL_NOTIFICATION: 'APP_CANCEL_NOTIFICATION',
  TOKEN_REFRESH: 'TOKEN_REFRESH',
  APPLE_LOGIN: 'APPLE_LOGIN',
  KAKAO_SHARE: 'KAKAO_SHARE',
  REAUTH_FOR_APP_UNREGISTER: 'REAUTH_FOR_APP_UNREGISTER',
  TOKEN_UPDATE: 'TOKEN_UPDATE',
  UNLINK: 'UNLINK',
} as const

export const APP_USER_AGENT = 'LunitCAREApp'
