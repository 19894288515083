import { CANCER_TYPES, KOR_CANCER_TYPES } from '@/consts/cancer'
import type { CancerType, KorCancerType } from '@/consts/cancer'
import { URL_PATH } from '@/consts/urls'

// Dependency cycle detected 가 발생해서 아래와 같이 import를 변경했습니다.
const HOTLINE_TELEPHONE = '070-7174-3737'

export const GNB_MENUS = [
  {
    menu: '루닛케어플러스',
    isUse: true,
    subMenus: [
      {
        text: '플러스 상담',
        url: URL_PATH.Plus,
        isBeta: false,
        gaSelector: 'menu_plus',
        parentUrl: '',
        highlightPaths: [URL_PATH.Plus],
      },
      {
        text: '플러스 리포트',
        url: URL_PATH.PlusSampleReport,
        privateUrl: URL_PATH.MyPlusReport,
        isBeta: false,
        gaSelector: 'menu_report',
        parentUrl: '',
        highlightPaths: [URL_PATH.PlusSampleReport, URL_PATH.MyPlusReport],
      },
    ],
  },
  {
    menu: '주제별 암 정보',
    isUse: true,
    subMenus: [
      {
        text: `${KOR_CANCER_TYPES.breast} 백과`,
        url: URL_PATH.GuidesMainBreast,
        isBeta: false,
        gaSelector: 'menu_guide_breast',
        parentUrl: URL_PATH.GuidesBreast,
        highlightPaths: [URL_PATH.GuidesMainBreast, URL_PATH.GuidesBreast],
      },
      {
        text: `${KOR_CANCER_TYPES.thyroid} 백과`,
        url: URL_PATH.GuidesMainThyroid,
        isBeta: false,
        gaSelector: 'menu_guide_thyroid',
        parentUrl: URL_PATH.GuidesThyroid,
        highlightPaths: [URL_PATH.GuidesMainThyroid, URL_PATH.GuidesThyroid],
      },
      {
        text: `${KOR_CANCER_TYPES.lung} 백과`,
        url: URL_PATH.GuidesMainLung,
        isBeta: false,
        gaSelector: 'menu_guide_lung',
        parentUrl: URL_PATH.GuidesLung,
        highlightPaths: [URL_PATH.GuidesMainLung, URL_PATH.GuidesLung],
      },
      {
        text: `${KOR_CANCER_TYPES.stomach} 백과`,
        url: URL_PATH.GuidesMainStomach,
        isBeta: false,
        gaSelector: 'menu_guide_stomach',
        parentUrl: URL_PATH.GuidesStomach,
        highlightPaths: [URL_PATH.GuidesMainStomach, URL_PATH.GuidesStomach],
      },
      {
        text: `${KOR_CANCER_TYPES.colon} 백과`,
        url: URL_PATH.GuidesMainColon,
        isBeta: false,
        gaSelector: 'menu_guide_colon',
        parentUrl: URL_PATH.GuidesColon,
        highlightPaths: [URL_PATH.GuidesMainColon, URL_PATH.GuidesColon],
      },
      {
        text: '생활백서',
        url: URL_PATH.GuidesLife,
        isBeta: false,
        gaSelector: 'menu_guide_life',
        parentUrl: '',
        highlightPaths: [URL_PATH.GuidesLife],
      },
    ],
  },
  {
    menu: '',
    isUse: true,
    subMenus: [
      {
        text: '병원 정보',
        url: URL_PATH.HospitalsMain,
        isBeta: false,
        gaSelector: 'menu_hospitals',
        parentUrl: '',
        highlightPaths: [URL_PATH.HospitalsMain],
      },
      {
        text: '항암 정보',
        url: URL_PATH.Regimens,
        privateUrl: URL_PATH.MyRegimens,
        isBeta: true,
        gaSelector: 'menu_regimens',
        parentUrl: '',
        highlightPaths: [URL_PATH.Regimens, URL_PATH.MyRegimens],
      },
    ],
  },
  {
    menu: '',
    isUse: true,
    subMenus: [
      {
        text: '질문과 답변',
        url: URL_PATH.Boards,
        isBeta: false,
        gaSelector: 'menu_boards',
        parentUrl: '',
        highlightPaths: [URL_PATH.Boards],
      },
      {
        text: '24시 핫라인',
        url: `tel:${HOTLINE_TELEPHONE}`,
        isBeta: false,
        gaSelector: 'menu_hotline',
        parentUrl: '',
        highlightPaths: [],
      },
      {
        text: '병원 예약',
        url: URL_PATH.HospitalsAppointment,
        privateUrl: URL_PATH.MyHospitalsAppointments,
        isBeta: false,
        gaSelector: 'menu_appointments',
        parentUrl: '',
        highlightPaths: [
          URL_PATH.HospitalsAppointment,
          URL_PATH.MyHospitalsAppointments,
        ],
      },
      {
        text: '치료비 계산기',
        url: URL_PATH.Calculator,
        isBeta: true,
        gaSelector: 'menu_calculator',
        parentUrl: '',
        highlightPaths: [URL_PATH.Calculator],
      },
      {
        text: 'AI 진료 노트',
        url: URL_PATH.AINotes,
        privateUrl: URL_PATH.MyNotes,
        isBeta: true,
        gaSelector: 'menu_notes',
        parentUrl: '',
        highlightPaths: [URL_PATH.AINotes, URL_PATH.MyNotes],
      },
      {
        text: 'AI 영양사',
        url: URL_PATH.Assistant,
        privateUrl: URL_PATH.MyAssistant,
        isBeta: true,
        gaSelector: 'menu_assistant',
        parentUrl: '',
        highlightPaths: [URL_PATH.Assistant, URL_PATH.MyAssistant],
      },
    ],
  },
  {
    menu: '',
    isUse: true,
    subMenus: [
      {
        text: '루닛케어 소개',
        url: URL_PATH.Service,
        isBeta: false,
        gaSelector: 'menu_service',
        parentUrl: '',
        highlightPaths: [URL_PATH.Service],
      },
      {
        text: '공지사항',
        url: URL_PATH.Notices,
        isBeta: false,
        gaSelector: 'menu_notices',
        parentUrl: '',
        highlightPaths: [URL_PATH.Notices],
      },
    ],
  },
]

export const TAB_MENUS = Object.entries(KOR_CANCER_TYPES)
  .filter((cancer) => cancer[0] !== CANCER_TYPES.colon)
  .map((cancer) => ({
    id: cancer[0] as CancerType,
    label: cancer[1] as KorCancerType,
  }))
