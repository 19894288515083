export * from './history'
export * from './menu'
export * from './questions'

/* 내 질문 상태 */
export const enum MY_QNA_STATUS {
  QNA_FINISH = 0,
  QNA_READY = 1,
}

/* 환자와의 관계 */
export enum PATIENT_RELATIONSHIP {
  본인 = '본인',
  보호자 = '보호자',
}
