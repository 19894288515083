export enum Statuses {
  '예방' = '예방',
  '의심' = '의심',
  '진단' = '진단',
  '치료' = '치료',
  '관리' = '관리',
  '완치' = '완치',
}

export const STATUSES = [
  Statuses.예방,
  Statuses.의심,
  Statuses.진단,
  Statuses.치료,
  Statuses.관리,
  Statuses.완치,
] as const
