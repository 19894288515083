export const QNA_PLACEHOLDER =
  '예시 질문 : 뭘 먹어야 할까요? / 항암치료 중에 이런 운동 괜찮을까요? / 암에 걸려도 임신계획이 가능할까요? / 부작용이 나타났어요. 저 괜찮을까요? (병기 / 치료 이력 / 가족력 등 건강상태를 상세히 알려주시면 더욱 자세히 조사한 답변을 제공해 드리겠습니다.)'

export const MAX_QNA_CONTENT_SIZE = 3000

export const QNA_STATUSES = [
  'INITIAL',
  'WAITING',
  'PROGRESS',
  'EDIT_WAITING',
  'ANSWERED',
] as const
