import { SITE_URLS } from '../config.js'

const REST_API_ENDPOINTS = {
  mock: 'https://backend-dev.lunit.care/api',
  develop: 'https://backend-dev.lunit.care/api',
  staging: 'https://backend-staging.lunit.care/api',
  production: 'https://backend.lunit.care/api',
}

//S3 host
const S3_HOST = 'https://static.lunit.care'
//S3에 저장된 이미지 host
const S3_IMAGE_HOST = `${S3_HOST}/images`
//S3에 저장된 아이콘 host
const S3_ICON_HOST = `${S3_HOST}/icons`

const S3_IMAGE_URLS = {
  mock: `${S3_IMAGE_HOST}`,
  develop: `${S3_IMAGE_HOST}`,
  staging: `${S3_IMAGE_HOST}`,
  production: `${S3_IMAGE_HOST}`,
}

const S3_ICON_URLS = {
  mock: `${S3_ICON_HOST}`,
  develop: `${S3_ICON_HOST}`,
  staging: `${S3_ICON_HOST}`,
  production: `${S3_ICON_HOST}`,
}

type Env = 'mock' | 'develop' | 'staging' | 'production'
export const env = (process.env.NEXT_PUBLIC_ENV as Env) ?? 'develop'

export const REST_API_ENDPOINT = REST_API_ENDPOINTS[env]
export const SITE_URL = SITE_URLS[env]
export const S3_IMAGE_URL = S3_IMAGE_URLS[env]
export const S3_ICON_URL = S3_ICON_URLS[env]
