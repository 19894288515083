export const COLORS = {
  black: '#060708',
  white: '#FFFFFF',
  blue: {
    0: '#F4F8FF',
    25: '#EBF2FF',
    50: '#B2CDFF',
    75: '#6590ff',
    100: '#2464E6',
  },
  gray: {
    10: '#F8F9FA',
    20: '#F2F4F6',
    30: '#EAECEE',
    40: '#DFE2E5',
    50: '#CED4DA',
    60: '#ABB4BD',
    70: '#9199A1',
    80: '#5F676F',
    90: '#414951',
    100: '#282D32',
  },
  green: {
    positive: '#B4EFC4',
    point: '#42BE65',
  },
  red: {
    negative: '#FA4D56',
  },
  yellow: {
    kakao: '#FEE500',
    point: '#FFC224',
  },
  brown: {
    kakao: '#191600',
  },
  teal: '#00C9EA',
  lavendar: {
    0: '#F4F2FF',
    10: '#B8AFF2',
    20: '#968BF2',
    30: '#7269F0',
    40: '#4A44EB',
    50: '#2E2EDD',
  },
  cyan: {
    0: '#F2FCFF',
    10: '#70EEFF',
    20: '#38DDFA',
    30: '#16CAF3',
    40: '#12ADD8',
    50: '#148FB8',
  },
  coral: {
    0: '#FFF1F3',
    10: '#FFDBC6',
    20: '#FFB3A3',
    30: '#FF9E91',
    40: '#FF7D7D',
    50: '#FF6E65',
  },
}
